import axios from "axios";

export const usersModule = {
  state: () => ({
    user: {
      name: null,
      last_name: null,
      second_name: null,
      email: null,
      role: null,
      domain: null,
    },
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    userIsAdmin(state) {
      return (state.user?.role?.map((el) => el.code) ?? []).includes("ADMIN");
    },
  },

  mutations: {
    setUser(state, payload) {
      const { name, second_name, last_name, email, domain, roles } = payload;

      state.user.name = name;
      state.user.role = roles;
      state.user.second_name = second_name;
      state.user.last_name = last_name;
      state.user.email = email;
      state.user.domain = domain;
    },
    setLoggedToken(state, token) {
      localStorage.setItem("token", token);
      location.href = '/';
    },
  },
  actions: {
    authUser({ commit }, data) {
      return axios
        .post("/api/auth/login", {
          username: data.username,
          password: data.password,
        })
        .then((response) => {
          const token = response.data.data_token.access_token;
          commit("setLoggedToken", token);
        })
        .catch((err) => {
          alert("Ошибка авторизации, введите правильные логин и пароль");
        });
    },
    authPerUser({ commit }, payload) {
      return axios
        .post(
          "/api/auth/login_per_user",
          {
            user_id: payload.user_id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          localStorage.setItem("parent_token", localStorage.getItem("token"));

          const token = response.data.data_token.access_token;
          commit("setLoggedToken", token);
        })
        .catch((err) => {
          alert("Ошибка авторизации, введите правильные логин и пароль");
        });
    },
    logoutUser({ commit }) {
      return axios("/api/auth/logout", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setUser", {});

          localStorage.removeItem("token");
          location.reload();
          return response;
        })
        .catch((e) => {
          console.log(e);
          return e;
        });
    },
    getUserFromAPI({ commit }) {
      return axios("/api/auth/me", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((requests) => {
          commit("setUser", requests.data);

          return requests;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
  },
};
