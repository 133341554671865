import { createStore } from "vuex";
import {settingsModule} from '@/store/settingsModule'
import {adminModule} from '@/store/adminModule';
import {usersModule} from '@/store/usersModule';

export default createStore( {
    modules: {
        settings: settingsModule,
        admin: adminModule,
        users: usersModule,
    }, 

})