<template>
  <div class="sidebar">
    <div class="logo">
      <img src="@/icons/logo.svg" alt=""/>
    </div>
    <div class="sidebar__list">
      <div class="sidebar__section">МЕНЮ</div>
      <sub-menu></sub-menu>
    </div>
  </div>
</template>

<script>
import SubMenu from './SubMenu'

export default {
  components: {
    SubMenu,
  },
};
</script>

<style scoped>

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.sidebar {
  width: 250px;
  min-height: 100vh;
  height: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  grid-area: sidebar;
}

.sidebar__list {
  position: sticky;
  top: 8px;
}

.sidebar__section {
  padding: 12px 20px;
  height: 37px;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #919da9;
}

li {
  list-style: none;
  padding: 10px 24px;
}
</style>
