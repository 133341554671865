export default function wordToLatin(word) {
  const cyrillicToLatinMap = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
    'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
    'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ъ': '',
    'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya', ' ': '-',
  };

  let latinResult = '';
  for (let i = 0; i < word.length; i++) {
    const char = word[i].toLowerCase();
    if (cyrillicToLatinMap.hasOwnProperty(char)) {
      latinResult += cyrillicToLatinMap[char];
    } else {
      latinResult += char;
    }
  }

  return latinResult;
}