import { createApp } from "vue";

import App from "./App";
import router from "@/router/router";
import store from "@/store";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.css"
import "@vuepic/vue-datepicker/dist/main.css";

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(moment)
  .directive("click-outside", clickOutside)
  .mount("#app");
