import axios from "axios";
import transformCityToDomain from "@/functions/transformCityToDomain";
import getQueryParams from "@/functions/getQueryParams";
import appendFormData from "@/functions/appendFormData";
import checkSendSizeData from "@/functions/checkSendSizeData";

export const settingsModule = {
  state: () => ({
    storeLoading: false,
    metrologs: [],
    cities: [],
    applications: [],
    clicks: [],
    reviews: [],
    sales: [],
    services: [],
    logs: {},
  }),
  getters: {
    getMetrologsList(state) {
      return state.metrologs;
    },
    getCitiesList(state) {
      return state.cities;
    },
    getApplicationsList(state) {
      return state.applications;
    },
    storeLoading(state) {
      return state.storeLoading;
    },
    getClicksList(state) {
      return state.clicks;
    },
    getReviewsList(state) {
      return state.reviews;
    },
    getSalesList(state) {
      return state.sales;
    },
    getServicesList(state) {
      return state.services;
    },
    getLogsData(state) {
      return state.logs;
    },
  },
  mutations: {
    setMetrologs(state, payload) {
      state.metrologs = payload;
    },
    updateCities(state, payload) {
      state.cities = payload;
    },
    addNewCity(state, city) {
      state.cities.push({
        NAME: city,
        CODE: transformCityToDomain(city),
      });
    },
    setApplications(state, payload) {
      state.applications = payload;
    },
    setLoader(state, status) {
      state.storeLoading = status;
    },
    updateApplication(state, payload) {
      state.applications.data = state.applications?.data?.map(application => {
        if (payload.id === application.id) {
          return {
            ...application,
            ...payload
          }
        } else {
          return application;
        }
      }) ?? [];
    },
    setSales(state, payload) {
      state.sales = payload;
    },
    updateSale(state, payload) {
      state.sales = state.sales.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          }
        } else {
          return item;
        }
      });
    },
    addSale(state, payload) {
      state.sales.push(payload);
    },
    deleteSale(state, id) {
      state.sales = state.sales.filter(el => el.id !== id);
    },
    setClicks(state, payload) {
      state.clicks = payload;
    },
    setReviews(state, payload) {
      state.reviews = payload;
    },
    addReview(state, payload) {
      state.reviews.push(payload)
    },
    updateReview(state, payload) {
      state.reviews = state.reviews.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          }
        } else {
          return item;
        }
      });
    },
    setServices(state, payload) {
      state.services = payload;
    },
    addService(state, payload) {
      state.services.push(payload);
    },
    updateService(state, payload) {
      state.services = state.services.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          }
        } else {
          return item;
        }
      });
    },
    setLogs(state, payload) {
      state.logs = payload;
    },
  },
  actions: {
    async getMetrologs({ commit }) {
      return await axios("/api/metrologs", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((requests) => {
          commit("setMetrologs", requests.data);

          return requests;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async saveMetrolog({ dispatch }, data) {
      try {
        await axios.post(`/api/metrologs/${data.id ?? ""}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        dispatch("getMetrologs");
      } catch (error) {
        console.log(error);
        alert(error.response.data.message);
      }
    },
    async updateMetrologStatus({ dispatch }, data) {
      await axios.post(`/api/metrologs/${data.id ?? ""}/status`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch("getMetrologs");
    },
    async getCitiesAvailable({ commit }) {
      return axios("/api/data/cities", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((requests) => {
          commit("updateCities", requests.data);

          return requests;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async getApplications({ commit }, params) {
      commit('setLoader', true);
      return await axios
        .get("/api/applications/?" + getQueryParams(params), {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          commit("setApplications", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        }).finally(() => {
          commit('setLoader', false);
        });
    },
    async updateApplications({ commit }, payload) {
      return await axios
        .post(`/api/applications/${payload.id}`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          commit("updateApplication", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        });
    },
    async getSales({ commit }, params) {
      commit('setLoader', true);
      return await axios.get("/api/sales/?" + getQueryParams(params), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setSales", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        }).finally(() => {
          commit('setLoader', false);
        });
    },
    async saveSaleData({ commit }, data) {
      let result = null;

      if (!checkSendSizeData(data)) {
        return;
      }
      commit('setLoader', true);


      try {
        result = await axios.post(`/api/sales/${data?.id ?? ""}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        result = e.response;
      }

      commit('setLoader', false);


      if (result.status !== 200) {
        alert(result.data?.message ?? "Ошибка");
        return result.data;
      }

      if (data?.id) {
        commit("updateSale", result.data);
      } else {
        commit("addSale", result.data);
      }

      return result.data;
    },
    async updateSaleStatus({ commit }, data) {
      return await axios.post(`/api/sales/${data.id}/status`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("updateSale", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        })
    },
    async dropSale({ commit }, data) {
      return await axios.delete(`/api/sales/${data.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("deleteSale", data.id);
          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        })
    },
    async getClicks({ commit }, params) {
      commit('setLoader', true);
      return await axios.get("/api/clicks/?" + getQueryParams(params), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setClicks", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        })
        .finally(() => {
          commit('setLoader', false);
        });
    },
    async getReviews({ commit }, params) {
      commit('setLoader', true);
      return await axios.get("/api/reviews/?" + getQueryParams(params), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setReviews", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        })
        .finally(() => {
          commit('setLoader', false);
        });
    },
    async saveReviewData({ commit }, data) {
      let result = null;

      if (!checkSendSizeData(data)) {
        return;
      }
      commit('setLoader', true);

      try {
        result = await axios.post(`/api/reviews/${data?.id ?? ""}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        result = e.response;
      }
      commit('setLoader', false);

      if (result.status !== 200) {
        alert(result.data?.message ?? "Ошибка");
        return result.data;
      }

      if (data?.id) {
        commit("updateReview", result.data);
      } else {
        commit("addReview", result.data);
      }

      return result.data;
    },
    async updateReviewStatus({ commit }, data) {
      return await axios.post(`/api/reviews/${data.id}/status`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("updateReview", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        })
    },
    async getServicesDefault() {
      return await axios.get("/api/services/default", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(result => {
        return result?.data ?? []
      }).catch((result) => {
        const response = result.response;
        alert(response?.data?.message ?? 'Ошибка');
        return response.data;
      });
    },
    async getServices({ commit }) {
      commit('setLoader', true);
      return await axios.get("/api/services", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        commit("setServices", response.data);
        return response;
      }).catch((error) => {
        console.log(error);
        alert(error.response.data.message);
        return error;
      }).finally(() => {
        commit('setLoader', false);
      });
    },
    async saveServiceData({ commit }, data) {
      let result = null;

      if (!checkSendSizeData(data)) {
        return;
      }
      commit('setLoader', true);

      try {
        result = await axios.post(`/api/services/${data?.id ?? ""}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        result = e.response;
      }
      commit('setLoader', false);

      if (result.status !== 200) {
        alert(result.data?.message ?? "Ошибка");
        return result.data;
      }

      if (data?.id) {
        commit("updateService", result.data);
      } else {
        commit("addService", result.data);
      }

      return result.data;
    },
    async getLogs({ commit }, params) {
      commit('setLoader', true);
      return await axios.get("/api/logs/?" + getQueryParams(params), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("setLogs", response.data);

          return response;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.message);
          return error;
        })
        .finally(() => {
          commit('setLoader', false);
        });
    },
  },
};
