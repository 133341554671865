export default {
  main: 'main',
  clicks: 'clicks',
  sales: 'sales',
  services: 'services',
  settings: 'settings',
  admin: 'admin',
  reviews: 'reviews',
  authorize: 'authorize',
  logs: 'logs',
  notFound: 'notFound',
}