import axios from "axios";
import getQueryParams from "@/functions/getQueryParams";
import appendFormData from "@/functions/appendFormData";
import checkSendSizeData from "@/functions/checkSendSizeData";

export const adminModule = {
  state: () => ({
    sites: [],
  }),
  getters: {
    getSitesList: (state) => {
      return state.sites;
    },
  },
  mutations: {
    setSites(state, payload) {
      state.sites = payload;
    },
    updateSite(state, payload) {
      state.sites = state.sites.map(site => {
        if (site.id === payload.id) {
          return {
            ...site,
            ...payload,
          }
        } else {
          return site;
        }
      });
    },
    addSite(state, payload) {
      state.sites.push(payload);
    }
  },
  actions: {
    async getSites({ commit }, params) {
      return await axios("/api/sites/?" + getQueryParams(params), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((requests) => {
          commit("setSites", requests.data);
          return requests;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async updateSiteStatus({ commit }, data) {
      if (!checkSendSizeData(data)) {
        return;
      }

      return await axios.post(`/api/sites/${data.id}/status`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
    async saveSiteData({ commit }, data) {
      let result = null;

      if (!checkSendSizeData(data)) {
        return;
      }

      commit('setLoader', true);

      try {
        result = await axios.post(`/api/sites/${data?.id ?? ""}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        result = e.response;
      }

      commit('setLoader', false);

      if (result.status !== 200) {
        alert(result.data?.message ?? "Ошибка");
        return result.data;
      }

      if (data?.id) {
        commit("updateSite", result.data.site);
      } else {
        commit("addSite", result.data.site);
      }

      return result.data;
    },
    async resetCredentials({}, data) {
      let result = null;
      try {
        result = await axios.post(`/api/sites/${data.id}/reset`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        result = e.response;
      }

      if (result.status !== 200) {
        alert(result.data?.message ?? "Ошибка");
        return result.data;
      }
      return result.data;
    }
  },
};
