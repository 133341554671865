<template>
  <div class="sub-menu" v-if="show === true">
    <sub-menu-item
        :key="`menu-${index}`"
        v-for="(item, index) in items"
        v-bind:item="item.text"
        :to="{ name: item.name }"
    />
  </div>
</template>

<script setup>
import SubMenuItem from "./SubMenuItem";
import routerEnum from "@/data/routerEnum";
import store from "@/store";
import { computed } from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
});

const items = computed(() => {
  const result = [
    { id: 1, text: "Заявки", name: routerEnum.main },
  ];

  if (store.getters.userIsAdmin) {
    result.push(
        { id: 3, text: "Сайты", name: routerEnum.admin },
        { id: 4, text: "Логи", name: routerEnum.logs },
    );
  } else {
    result.push(
        { id: 2, text: "Клики", name: routerEnum.clicks },
        { id: 3, text: "Акции", name: routerEnum.sales },
        { id: 4, text: "Услуги", name: routerEnum.services },
        { id: 5, text: "Контакты", name: routerEnum.settings },
        { id: 6, text: "Отзывы", name: routerEnum.reviews }
    );
  }

  return result;
});
</script>

<style>
.sub-menu {
  padding: 0 0 0 28px;
}
</style>
