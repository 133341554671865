import wordToLatin from "@/functions/wordToLatin";

export default function transformCityToDomain(city) {
  city = city.toLowerCase();
  city = wordToLatin(city);
  city = city.trim();
  city = city.replace(/\s+/g, '_');
  city = city.replace(/[^a-zа-я_]/g, '');

  let words = city.split('_');

  return words.join('-');
}