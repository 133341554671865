export default function (data) {
  const strData = JSON.stringify(data);
  const dataSize = new Blob([strData]).size;
  const sizeMb = dataSize / 1024 / 1024;

  console.log(dataSize, sizeMb);
  if (sizeMb > 25) {
    alert('Размер вашего запроса слишком большой, он может обрабатываться очень долго или вообще не выполниться! Попробуйте уменьшить размер загружаемых фотографий или уменьшить количество контента');
    return confirm('Хотите ли вы продолжить выполнение запроса?')
  }

  return true;
}