import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "@/store";

const devModeRouter = false;

const routes = [
    {
        path: "/",
        name: "main",
        component: async () => import("@/pages/RequestsPage"),
    },
    {
        path: "/clicks/",
        name: "clicks",
        component: async () => import("@/pages/ClicksPage"),
    },
    {
        path: "/sales/",
        name: "sales",
        component: async () => import("@/pages/SalesPage"),
    },
    {
        path: "/services/",
        name: "services",
        component: async () => import("@/pages/ServicesPage"),
    },
    {
        path: "/settings/",
        name: "settings",
        component: async () => import("@/pages/SettingsPage"),
    },

    {
        path: "/admin/",
        name: "admin",
        component: async () => import("@/pages/AdminMain"),
    },

    {
        path: "/reviews/",
        name: "reviews",
        component: async () => import("@/pages/ReviewsPage"),
    },

    {
        path: "/auth/",
        name: "authorize",
        component: async () => import("@/pages/Autorisation"),
    },
    {
        path: "/logs/",
        name: "logs",
        component: async () => import("@/pages/UserLogs"),
    },
    {
        path: "/:catchAll(.*)?",
        name: "notFound",
        component: async () => import("@/pages/NotFound.vue"),
        meta: {
            displayName: "Страница не найдена",
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
});

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);
    if (!devModeRouter) {
        const token = localStorage.getItem("token");

        if (!token) {
            if (to.name === "authorize") {
                return next();
            } else {
                return next({ name: "authorize" });
            }
        }

        try {
            const response = await axios.post("/api/auth/me", false, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status !== 200) {
                store.commit("setUser", {});
                localStorage.removeItem("token");
                return next({ name: "authorize" });
            }

            store.commit("setUser", response.data);

            if (to.name === 'authorize') {
                return next({ name: 'main' });
            }
        } catch (e) {
            console.log(e);
            localStorage.removeItem("token");
            return next({ name: "authorize" });
        }
    }

    return next();
});

export default router;
